import "./style.css";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import deleteIcon from "../../assets/delete.svg";
import renameIcon from "../../assets/rename.svg";
import copyIcon from "../../assets/copy.svg";
import visibilityIcon from "../../assets/visibility.svg";
import moveIcon from "../../assets/move.svg";
import pinIcon from "../../assets/pin.svg";
import infoIcon from "../../assets/info.svg";
import downloadIcon from "../../assets/download.svg";
import { DataContext } from "../../context/DataContext";
import { ERROR_MESSAGE } from "../../Constants/errorMessages";

async function deleteFile(fileId, setMessage) {
	if (!fileId) {
		setMessage({
			error: true,
			message: "Invalid file!",
		});
		return;
	}

	try {
		const { data } = await axios.delete(`/file/${fileId}`);

		if (data.success) {
			setMessage({
				error: false,
				message: data.message,
			});

			setTimeout(() => {
				window.location.reload();
			}, 1000);
		} else {
			setMessage({
				error: true,
				message: data.message,
			});
		}
	} catch (error) {
		setMessage({
			error: true,
			message: ERROR_MESSAGE,
		});
	}
}

async function toggleACL(fileId, setMessage) {
	if (!fileId) {
		setMessage({
			error: true,
			message: "Invalid file!",
		});

		return;
	}

	try {
		const { data } = await axios.patch(`/file/acl/${fileId}`);

		if (data.success) {
			setMessage({
				error: false,
				message: data.message,
			});

			setTimeout(() => {
				window.location.reload();
			}, 1000);
		} else {
			setMessage({
				error: true,
				message: data.message,
			});
		}
	} catch (error) {
		setMessage({
			error: true,
			message: ERROR_MESSAGE,
		});
	}
}

export async function generateLink(fileId, setMessage, copyToClipboard = true) {
	if (!fileId) {
		setMessage({
			error: true,
			message: "Invalid file!",
		});

		return;
	}
	try {
		const { data } = await axios.get(`/file/signed/${fileId}`);

		if (data.success) {
			if (copyToClipboard && data.data.url) {
				navigator.clipboard.writeText(data.data.url);

				setMessage({
					error: false,
					message: "Link copied to clipboard!",
				});
			} else {
				return data.data.url;
			}
		} else {
			setMessage({
				error: true,
				message: data.message,
			});
			return null;
		}
	} catch (error) {
		setMessage({
			error: true,
			message: ERROR_MESSAGE,
		});
		return null;
	}
}

async function renameFile(fileId, fileName, setMessage) {
	if (!fileId || !fileName) {
		setMessage({
			error: true,
			message: "Please enter a valid file name",
		});
		return;
	}

	try {
		const { data } = await axios.patch(`/file/rename/${fileId}`, {
			updatedName: fileName.toLowerCase().trim(),
		});

		if (data.success) {
			setMessage({
				error: false,
				message: data.message,
			});

			setTimeout(() => {
				window.location.reload();
			}, 1000);
		} else {
			setMessage({
				error: true,
				message: data.message,
			});
		}
	} catch (error) {
		setMessage({
			error: true,
			message: ERROR_MESSAGE,
		});
	}
}

async function moveFile(fileId, directoryId, setMessage) {
	if (!fileId || !directoryId) {
		setMessage({
			error: true,
			message: "Please enter a valid directory name",
		});
		return;
	}

	try {
		const { data } = await axios.patch(`/file/move/${fileId}/${directoryId}`);

		if (data.success) {
			setMessage({
				error: false,
				message: data.message,
			});

			setTimeout(() => {
				window.location.reload();
			}, 1000);
		} else {
			setMessage({
				error: true,
				message: data.message,
			});
		}
	} catch (error) {
		setMessage({
			error: true,
			message: ERROR_MESSAGE,
		});
	}
}

async function pinFile(fileId, setMessage) {
	if (!fileId) {
		setMessage({
			error: true,
			message: "Invalid file!",
		});
		return;
	}

	try {
		const { data } = await axios.post(`/file/pin/${fileId}`);
		if (data.success) {
			setMessage({
				error: false,
				message: data.message,
			});

			setTimeout(() => {
				window.location.reload();
			}, 1000);
		} else {
			setMessage({
				error: true,
				message: data.message,
			});
		}
	} catch (error) {
		setMessage({
			error: true,
			message: ERROR_MESSAGE,
		});
	}
}

export default function ActionsFile({ setShowFileActions, X, Y, setShowMove }) {
	const {
		setMessage,
		selectedFile,
		setViewDetails,
		setSelectedFile,
		setDirectoryId,
	} = useContext(DataContext);

	const [fileName, setFileName] = useState("");
	const actionsFileRef = useRef();

	useEffect(() => {
		if (actionsFileRef.current) {
			actionsFileRef.current.style.left = X + window.scrollX + "px";
			actionsFileRef.current.style.top = Y + window.scrollY + "px";
		}
	}, [X, Y]);

	return (
		<Fragment>
			<ul
				ref={actionsFileRef}
				className="actions"
				onMouseLeave={() => {
					setShowFileActions(false);
				}}
			>
				<li
					onClick={() => {
						setShowMove(true);
					}}
				>
					<img src={moveIcon} alt="move" />
					Move To
				</li>
				<li
					onClick={() => {
						toggleACL(selectedFile._id, setMessage);

						setShowFileActions(false);
					}}
				>
					<img src={visibilityIcon} alt="ACL" />
					{selectedFile?.ACL === "private" ? "Make Public" : "Make Private"}
				</li>
				{selectedFile?.ACL === "private" ? (
					<li
						onClick={() => {
							generateLink(selectedFile._id, setMessage);
							setShowFileActions(false);
						}}
					>
						<img src={copyIcon} alt="ACL" />
						Generate Link
					</li>
				) : null}
				<li
					onClick={() => {
						navigator.clipboard.writeText(selectedFile.url);

						setShowFileActions(false);
					}}
				>
					<img src={copyIcon} alt="ACL" />
					Copy Original Link
				</li>
				<hr className="actions-hr" />
				<li>
					<img src={renameIcon} alt="rename" />
					<input
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								renameFile(
									selectedFile._id,
									fileName.trim().toLowerCase(),
									setMessage
								);
							}
						}}
						onChange={(e) => {
							setFileName(e.target.value.trim().toLowerCase());
						}}
						placeholder="Rename"
						id="rename-file"
					/>
				</li>
				<hr className="actions-hr" />
				<li
					onClick={() => {
						deleteFile(selectedFile._id, setMessage);

						setShowFileActions(false);
					}}
				>
					<img src={deleteIcon} alt="delete" />
					Delete
				</li>
				<hr className="actions-hr" />
				<li
					onClick={() => {
						setViewDetails(true);
						setSelectedFile({ ...selectedFile });
						setDirectoryId(null);

						setShowFileActions(false);
					}}
				>
					<img src={infoIcon} alt="details" />
					Details
				</li>
				<li
					onClick={() => {
						pinFile(selectedFile._id, setMessage);

						setShowFileActions(false);
					}}
				>
					<img src={pinIcon} alt="pin" />
					Pin
				</li>
				<li>
					<a
						// href={(async () => {
						// 	return selectedFile.ACL === "private"
						// 		? await generateLink(selectedFile._id, setMessage, false)
						// 		: selectedFile.url;
						// })()}
						onClick={() => {
							if (selectedFile.ACL === "private") {
								generateLink(selectedFile._id, setMessage, false).then(
									(url) => {
										window.open(url, "_blank");
									}
								);
							} else {
								window.open(selectedFile.url, "_blank");
							}
						}}
						target="_blank"
						rel="noreferrer"
						style={{
							width: "100%",
							display: "flex",
							gap: 20,
							alignItems: "center",
							textDecoration: "none",
							color: "black",
						}}
					>
						<img src={downloadIcon} alt="download" />
						Download
					</a>
				</li>
			</ul>
		</Fragment>
	);
}
